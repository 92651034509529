import axios from "axios";
import endPoint from "./Endpoints";
import { values } from "lodash";

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/api",
  headers: {
    Accept: "application/json",
  },
});

httpClient.interceptors.request.use(
  (config) => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      config.headers.common = { Authorization: `Bearer ${user.token}` };
      config.headers["x-requested-tenantid"] = user.tenantId;
    }
    return config;
  },
  (error) => {
    Promise.reject(error.response || error.message);
  }
);

httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let response = error.response;
    if (!response) {
      response = {
        status: 0,
        data: error.message,
      };
    }

    if (localStorage.user && response.status === 401) {
      sessionExpiredCallback();
    }

    return Promise.reject(response);
  }
);

const sessionExpiredCallback = () => {
  localStorage.removeItem("user");
  window.location.reload();
};

export const getPets = () => {
  return httpClient.get(endPoint.PETS);
};
export const getPet = (id) => {
  return httpClient.get(`${endPoint.PETS}/${id}`);
};
export const createPet = (payload) => {
  return httpClient.post(endPoint.PETS, payload);
};
export const updatePet = (id, payload) => {
  return httpClient.put(`${endPoint.PETS}/${id}`, payload);
};
export const deletePet = (id) => {
  return httpClient.delete(`${endPoint.PETS}/${id}`);
};

/* Members methods */

export const getMember = (id) => {
  return httpClient.get(`${endPoint.MEMBERS}/${id}`);
};
export const createMember = (payload) => {
  return httpClient.post(endPoint.MEMBERS, payload);
};
export const updateMember = (id, payload) => {
  return httpClient.put(`${endPoint.MEMBERS}/${id}`, payload);
};

/* Members HouseHold details */

export const createHousehold = (payload) => {
  return httpClient.post(endPoint.HOUSEHOLD, payload);
};
export const updateHousehold = (id, payload) => {
  return httpClient.put(`${endPoint.MEMBERS}/${id}`, payload);
};
export const getHouseHoldDetail = (id) => {
  return httpClient.get(`${endPoint.HOUSEHOLDS_DETAILS}/${id}`);
};
export const getHouseHoldDetails = () => {
  return httpClient.get(`${endPoint.HOUSEHOLDS_DETAILS}`);
};
export const deleteHousehold = (id) => {
  return httpClient.delete(`${endPoint.HOUSEHOLDS_DETAILS}/${id}`);
};
export const updateHouseHoldAddress = (id, values) => {
  return httpClient.post(`${endPoint.UPDATE_HOUSEHOLDS_ADDRESS}/${id}`, values);
};
export const updateHouseHoldUnits = (id, values) => {
  return httpClient.post(`${endPoint.UPDATE_HOUSEHOLDS_UNITS}/${id}`, values);
};

/* Vehicles methods */

export const getVehicles = (id) => {
  return httpClient.get(`${endPoint.VEHICLES}/${id}`);
};
export const createVehicles = (payload) => {
  return httpClient.post(endPoint.VEHICLES, payload);
};
export const updateVehicles = (id, payload) => {
  return httpClient.put(`${endPoint.VEHICLES}/${id}`, payload);
};
export const deleteVehicle = (id) => {
  return httpClient.delete(`${endPoint.VEHICLES}/${id}`);
};

/* Admin Setting  methods */
export const createAdminSetting = (payload) => {
  return httpClient.post(endPoint.ORGINAZATIONS, payload);
};

export const getAdminSetting = (id) => {
  return httpClient.get(`${endPoint.ORGINAZATIONS}/${id}`);
};
export const getOrginzations = (params = null) => {
  return httpClient.get(`${endPoint.ORGINAZATIONS}`, { params: params });
};
export const updateOrganisazation = (id, payload) => {
  return httpClient.put(`${endPoint.ORGINAZATION}/${id}`, payload);
};

/* lease  methods */
export const createLease = (payload) => {
  return httpClient.post(endPoint.LEASE, payload);
};
export const createManager = (payload) => {
  return httpClient.post(endPoint.MANAGER, payload);
};
export const getManagerId = (id) => {
  return httpClient.get(`${endPoint.MANAGER}/${id}`);
};
export const updateManager = (id, payload) => {
  return httpClient.put(`${endPoint.MANAGER}/${id}`, payload);
};

export const getLease = () => {
  return httpClient.get(endPoint.LEASE);
};
export const getLeaseById = (id) => {
  return httpClient.get(`${endPoint.LEASE}/${id}`);
};
export const getLeaseTextFields = () => {
  return httpClient.get(endPoint.LEASEfIELDS);
};
export const updateLease = (id, payload) => {
  return httpClient.put(`${endPoint.LEASE}/${id}`, payload);
};
//Applicant Uploads /applicant_uploads
export const createUpload = (payload) => {
  return httpClient.post(endPoint.UPLOAD_DOC, payload);
};
export const getUploadId = (id) => {
  return httpClient.get(`${endPoint.UPLOAD_DOC}/${id}`);
};

// sign lease invite
export const SendLeaseInvite = (payload) => {
  return httpClient.post(endPoint.INVITE_LEASE, payload);
};
export const SendLeaseNotification = (payload) => {
  return httpClient.post(endPoint.SEND_LEASE_NOTIFICATION, payload);
};

// Library
export const libraries = () => {
  return httpClient.get(endPoint.LIBRARIES);
};
export const addedLibraryLis = (payload) => {
  return httpClient.post(endPoint.LIBRARY, payload);
};
export const createLibraries = (payload) => {
  return httpClient.post(endPoint.LIBRARIES, payload);
};
export const library = (id) => {
  return httpClient.get(`${endPoint.LIBRARIES}/${id}`);
};
export const updateLibrary = (id, payload) => {
  return httpClient.put(`${endPoint.LIBRARIES}/${id}`, payload);
};
export const updateLibraryStatus = (id, payload) => {
  return httpClient.put(`${endPoint.LIBRARIES}/${id}`, payload);
};
export const getLibrary = (payload) => {
  return httpClient.post(endPoint.LIBRARIES, payload);
};
export const deleteLibrary = (id) => {
  return httpClient.delete(`${endPoint.LIBRARIES}/${id}`);
};

//Order Background
export const CreateOrder = (payload) => {
  return httpClient.post(endPoint.ORDERBACKGROUND, payload);
};
export const getOrder = (id) => {
  return httpClient.get(`${endPoint.ORDERBACKGROUND}/${id}`);
};
export const getReport = (id) => {
  return httpClient.get(`${endPoint.GETREPORT}/${id}`);
};

export const adverseAction = (payload) => {
  return httpClient.post(endPoint.ADVERSCEACTION, payload);
};
// Property
export const properties = () => {
  return httpClient.get(`${endPoint.PROPERTIES}`);
};

export const createProperties = (payload) => {
  return httpClient.post(endPoint.PROPERTIES, payload);
};
export const Property = (id) => {
  return httpClient.get(`${endPoint.PROPERTIES}/${id}`);
};
export const getProperty = (uid) => {
  return httpClient.get(`${endPoint.PROPERTY_BY_UID}/${uid}`);
};
export const updateProperties = (id, payload) => {
  return httpClient.put(`${endPoint.PROPERTIES}/${id}`, payload);
};
export const deleteProperty = (id) => {
  return httpClient.delete(`${endPoint.PROPERTIES}/${id}`);
};

//Floor

export const floor = () => {
  return httpClient.get(`${endPoint.FLOOR}`);
};

export const createFloor = (payload) => {
  return httpClient.post(endPoint.FLOOR, payload);
};
export const floorById = (id) => {
  return httpClient.get(`${endPoint.FLOOR}/${id}`);
};
export const updateFloor = (id, payload) => {
  return httpClient.put(`${endPoint.FLOOR}/${id}`, payload);
};

//Form Setting

export const formSetting = () => {
  return httpClient.get(`${endPoint.FORM_SETTING}`);
};

export const createFormSetting = (payload) => {
  return httpClient.post(endPoint.FORM_SETTING, payload);
};
export const formSettingById = (id) => {
  return httpClient.get(`${endPoint.FORM_SETTING}/${id}`);
};
export const updateFormSetting = (id, payload) => {
  return httpClient.put(`${endPoint.FORM_SETTING}/${id}`, payload);
};
export const getFormSettingJoined = (id) => {
  return httpClient.get(`${endPoint.PROPERTY_SETTING_JOINED}/${id}`);
};

//New Fee Config

export const newFeeConfig = () => {
  return httpClient.get(`${endPoint.FEE_CONFIG}`);
};

export const createnewFeeConfig = (payload) => {
  return httpClient.post(endPoint.FEE_CONFIG, payload);
};
export const newFeeConfigById = (id) => {
  return httpClient.get(`${endPoint.FEE_CONFIG}/${id}`);
};
export const updateNewFeeConfig = (id, payload) => {
  return httpClient.put(`${endPoint.FEE_CONFIG}/${id}`, payload);
};
export const deleteNewFeeConfig = (id) => {
  return httpClient.delete(`${endPoint.FEE_CONFIG}/${id}`);
};

//Background check

export const bcgCheck = () => {
  return httpClient.get(`${endPoint.BACKGROUND_CHECK}`);
};

export const createbcgCheck = (payload) => {
  return httpClient.post(endPoint.BACKGROUND_CHECK, payload);
};
export const bcgCheckById = (id) => {
  return httpClient.get(`${endPoint.BACKGROUND_CHECK}/${id}`);
};
export const updatebcgCheck = (id, payload) => {
  return httpClient.put(`${endPoint.BACKGROUND_CHECK}/${id}`, payload);
};
export const deletebcgCheck = (id) => {
  return httpClient.delete(`${endPoint.BACKGROUND_CHECK}/${id}`);
};

//LEASE SETTING

export const leaseSetting = () => {
  return httpClient.get(`${endPoint.LEASE_SETTING}`);
};

export const createleaseSetting = (payload) => {
  return httpClient.post(endPoint.LEASE_SETTING, payload);
};
export const leaseSettingById = (id) => {
  return httpClient.get(`${endPoint.LEASE_SETTING}/${id}`);
};
export const updateleaseSetting = (id, payload) => {
  return httpClient.put(`${endPoint.LEASE_SETTING}/${id}`, payload);
};

//Form Preset

export const formPreset = () => {
  return httpClient.get(`${endPoint.FORM_PRESET}`);
};

export const createformPreset = (payload) => {
  return httpClient.post(endPoint.FORM_PRESET, payload);
};
export const formPresetById = (id) => {
  return httpClient.get(`${endPoint.FORM_PRESET}/${id}`);
};
export const updateformPreset = (id, payload) => {
  return httpClient.put(`${endPoint.FORM_PRESET}/${id}`, payload);
};
export const deleteById = (id) => {
  return httpClient.delete(`${endPoint.FORM_PRESET}/${id}`);
};
export const copyformPreset = (id) => {
  return httpClient.post(`${endPoint.FORM_PRESET}/${id}`);
};
export const updateLabels = (id, payload) => {
  return httpClient.put(`${endPoint.FORM_PRESET_LABELS}/${id}`, payload);
};
export const labelsById = (id) => {
  return httpClient.get(`${endPoint.FORM_PRESET_LABELS}/${id}`);
};

//INSCRIBE

export const getFileStatus = (payload) => {
  return httpClient.post(endPoint.INSCRIBESTATUS, payload);
};

//MERCHANT
export const merchant = () => {
  return httpClient.get(`${endPoint.MERCHANT}`);
};

export const createMerchant = (payload) => {
  return httpClient.post(endPoint.MERCHANT, payload);
};
export const merchantById = (id) => {
  return httpClient.get(`${endPoint.MERCHANT}/${id}`);
};
export const updateMerchant = (id, payload) => {
  return httpClient.put(`${endPoint.MERCHANT}/${id}`, payload);
};
export const deleteMerchant = (id) => {
  return httpClient.delete(`${endPoint.MERCHANT}/${id}`);
};

//:"retrieve_customer"
export const retrieveCustomer = (id) => {
  return httpClient.get(`${endPoint.RETRIEVE_CUSTOMER}/${id}`);
};
export const addBankAccount = (payload) => {
  return httpClient.post(endPoint.ADD_BANK_ACCOUNT, payload);
};

//Show deopists
export const showDeposit = (id) => {
  return httpClient.get(`${endPoint.SHOW_DEPOSITS}/${id}`);
};
//User

export const user = () => {
  return httpClient.get(`${endPoint.USER}`);
};

export const createUser = (payload) => {
  return httpClient.post(endPoint.USER, payload);
};
export const getUserById = (id) => {
  return httpClient.get(`${endPoint.USER}/${id}`);
};
export const updateUser = (id, payload) => {
  return httpClient.put(`${endPoint.USER}/${id}`, payload);
};
export const updateUserStatus = (id, payload) => {
  return httpClient.put(`${endPoint.USERLOCKSTATUS}/${id}`, payload);
};
export const updatePassword = (id, payload) => {
  return httpClient.put(`${endPoint.UPDATE_PASSWORD}/${id}`, payload);
};

// application module implementation
//Applicant_info
export const Applicant = () => {
  return httpClient.get(`${endPoint.APPLICANT_INFO}`);
};
export const ApplicantOrg = () => {
  return httpClient.get(`${endPoint.APPLICANT_INFO_ORG}`);
};

export const getSelectedApplicant = (id) => {
  return httpClient.get(`${endPoint.APPLICANT_INFO}/${id}`);
};

export const createApplicant = (payload) => {
  return httpClient.post(endPoint.APPLICANT_INFO, payload);
};
export const createAdminApplicant = (payload) => {
  return httpClient.post(endPoint.ADMIN_APPLICANT_INFO, payload);
};
export const createApplicantAndVerify = (payload) => {
  return httpClient.post(endPoint.APPLICANTVERIFY, payload);
};

export const archiveApplication = (id) => {
  return httpClient.delete(`${endPoint.APPLICANT_INFO}/${id}`);
};

export const getarchiveApplication = () => {
  return httpClient.get(`${endPoint.ARCHIVED}`);
};

export const unArchiveApplication = (id) => {
  return httpClient.put(`${endPoint.UNARCHIVED}/${id}`);
};

export const updateApplicant = (id, payload) => {
  return httpClient.put(`${endPoint.APPLICANT_INFO}/${id}`, payload);
};

export const mergeApplicant = (payload) => {
  return httpClient.post(endPoint.APPLICANT_INFO_MERGE, payload);
};

//pre disclosure
export const createPreDisclosure = (payload) => {
  return httpClient.post(endPoint.PREDISCLOSURE, payload);
};

//Applicant_info
export const getselectedPropertyInfo = (id) => {
  return httpClient.get(`${endPoint.PROPERTY_INFO}/${id}`);
};

export const createPropertyInfo = (payload) => {
  return httpClient.post(endPoint.PROPERTY_INFO, payload);
};
export const adminPropertyInfo = (payload) => {
  return httpClient.post(endPoint.ADMIN_PROPERTY_INFO, payload);
};

//current address
export const createCurrentAddress = (payload) => {
  return httpClient.post(endPoint.CURRENT_ADDRESS, payload);
};
export const createAdminCurrentAddress = (payload) => {
  return httpClient.post(endPoint.ADMIN_CURRENT_ADDRESS, payload);
};
export const getselectedCurrentAddress = (id) => {
  return httpClient.get(`${endPoint.CURRENT_ADDRESS}/${id}`);
};

//previous address
export const createPreviousAddress = (payload) => {
  return httpClient.post(endPoint.PREVIOUS_ADDRESS, payload);
};
export const createAdminPreviousAddress = (payload) => {
  return httpClient.post(endPoint.ADMIN_PREVIOUS_ADDRESS, payload);
};
export const getselectedPreviousAddress = (id) => {
  return httpClient.get(`${endPoint.PREVIOUS_ADDRESS}/${id}`);
};

//other source of income
export const createOrUpdateOtherIncome = (payload) => {
  return httpClient.post(endPoint.OTHER_SOURCE_INCOME, payload);
};
export const createOrUpdateAdminOtherIncome = (payload) => {
  return httpClient.post(endPoint.ADMIN_OTHER_SOURCE_INCOME, payload);
};
export const getselectedOtherIncome = (id) => {
  return httpClient.get(`${endPoint.OTHER_SOURCE_INCOME}/${id}`);
};

//Pets
export const createOrUpdatePets = (payload) => {
  return httpClient.post(endPoint.APPLICATION_PETS, payload);
};
export const createOrUpdateAdminPets = (payload) => {
  return httpClient.post(endPoint.ADMIN_APPLICATION_PETS, payload);
};
export const getselectedPets = (id) => {
  return httpClient.get(`${endPoint.APPLICATION_PETS}/${id}`);
};

//Pets
export const createOrUpdateVechicles = (payload) => {
  return httpClient.post(endPoint.APPLICATION_VECHILES, payload);
};
export const createOrUpdateAdminVechicles = (payload) => {
  return httpClient.post(endPoint.ADMIN_APPLICATION_VECHILES, payload);
};
export const getselectedVechicles = (id) => {
  return httpClient.get(`${endPoint.APPLICATION_VECHILES}/${id}`);
};

//BankAccount
export const createOrUpdateBankAccount = (payload) => {
  return httpClient.post(endPoint.APPLICATION_BANKING, payload);
};
export const getselectedBankAccount = (id) => {
  return httpClient.get(`${endPoint.APPLICATION_BANKING}/${id}`);
};

//Personal-Refernce
export const createOrUpdatePReference = (payload) => {
  return httpClient.post(endPoint.APPLICATION_PERSONAL_REFERENCE, payload);
};
export const createOrUpdateAdminPReference = (payload) => {
  return httpClient.post(
    endPoint.ADMIN_APPLICATION_PERSONAL_REFERENCE,
    payload
  );
};
export const getselectedPReference = (id) => {
  return httpClient.get(`${endPoint.APPLICATION_PERSONAL_REFERENCE}/${id}`);
};

//Personal-Refernce
export const createOrUpdateEmergencyContact = (payload) => {
  return httpClient.post(endPoint.APPLICATION_EMERGENCYCONTACT, payload);
};
export const createOrUpdateAdminEmergencyContact = (payload) => {
  return httpClient.post(endPoint.ADMIN_APPLICATION_EMERGENCYCONTACT, payload);
};
export const getselectedEmergencyContact = (id) => {
  return httpClient.get(`${endPoint.APPLICATION_EMERGENCYCONTACT}/${id}`);
};

//Other Question
export const createOrUpdateOtherQuestion = (payload) => {
  return httpClient.post(endPoint.APPLICATION_OTHERQUESTION, payload);
};
export const createOrUpdateAdminOtherQuestion = (payload) => {
  return httpClient.post(endPoint.ADMIN_APPLICATION_OTHERQUESTION, payload);
};
export const getselectedOtherQuestion = (id) => {
  return httpClient.get(`${endPoint.APPLICATION_OTHERQUESTION}/${id}`);
};
//applicant utility
export const createApplicantUtility = (payload) => {
  return httpClient.post(endPoint.APPLICANT_UTILITY, payload);
};
export const getSelectedApplicantUtility = (id) => {
  return httpClient.get(`${endPoint.APPLICANT_UTILITY}/${id}`);
};
export const updateApplicantUtility = (id, payload) => {
  return httpClient.put(`${endPoint.APPLICANT_UTILITY}/${id}`, payload);
};
export const updateAuthApplicantUtility = (id, payload) => {
  return httpClient.put(`${endPoint.APPLICANT_UTILITY_AUTH}/${id}`, payload);
};

export const connect = () => {
  return httpClient.get(endPoint.CONNECT);
};

//curent employer
export const createOrUpdateCurEmpl = (payload) => {
  return httpClient.post(endPoint.APPLICANT_CURR_EMPL, payload);
};
export const createOrUpdateAdminCurEmpl = (payload) => {
  return httpClient.post(endPoint.ADMIN_APPLICANT_CURR_EMPL, payload);
};
export const getSelectedCurEmpl = (id) => {
  return httpClient.get(`${endPoint.APPLICANT_CURR_EMPL}/${id}`);
};

//previous employer
export const createOrUpdatePrevEmpl = (payload) => {
  return httpClient.post(endPoint.APPLICANT_PREV_EMPL, payload);
};
export const createOrUpdateAdminPrevEmpl = (payload) => {
  return httpClient.post(endPoint.ADMIN_APPLICANT_PREV_EMPL, payload);
};
export const getSelectedPrevEmpl = (id) => {
  return httpClient.get(`${endPoint.APPLICANT_PREV_EMPL}/${id}`);
};
export const sendInvite = (payload) => {
  return httpClient.post(`${endPoint.SEND_INVITE}`, payload);
};
export const createPassword = (payload) => {
  return httpClient.post(`${endPoint.CREATE_PASSWORD}`, payload);
};

//stripe methods

export const createPaymentIntent = (payload) => {
  return httpClient.post(endPoint.CREATE_PAYMENT_INTENT, payload);
};

//payment details

export const createPaymentDetails = (payload) => {
  return httpClient.post(endPoint.CREATE_PAYMENT_DETAILS, payload);
};
export const getPaymentDetails = (id) => {
  return httpClient.get(`${endPoint.CREATE_PAYMENT_DETAILS}/${id}`);
};
export const getAllPaymentDetails = () => {
  return httpClient.get(`${endPoint.CREATE_PAYMENT_DETAILS}`);
};

//Billing details

export const createBilling = (payload) => {
  return httpClient.post(endPoint.BILLING, payload);
};
export const getBilling = () => {
  return httpClient.get(`${endPoint.BILLING}`);
};

//pddBuilder updates
export const createTextfield = (payload) => {
  return httpClient.post(endPoint.PDFBUILDER, payload);
};
export const updateTextField = (id, payload) => {
  return httpClient.put(`${endPoint.PDFBUILDER}/${id}`, payload);
};
export const getPdfData = (id) => {
  return httpClient.get(`${endPoint.PDFBUILDER}/${id}`);
};

//Custom Fields
export const createLibraryCustomFields = (payload) => {
  return httpClient.post(endPoint.LIBRARY_CUSTOM_FIELDS, payload);
};
export const getCustomFields = (id) => {
  return httpClient.get(`${endPoint.LIBRARY_CUSTOM_FIELDS}`);
};
export const getCustomFieldsBYId = (id) => {
  return httpClient.get(`${endPoint.LIBRARY_CUSTOM_FIELDS}/${id}`);
};

export const updateCustomFields = (id, payload) => {
  return httpClient.put(`${endPoint.LIBRARY_CUSTOM_FIELDS}/${id}`, payload);
};

export const createLeaseCustomFields = (payload) => {
  return httpClient.post(endPoint.LEASECUTOMFIELDS, payload);
};
export const getLeaseCustomFields = (id) => {
  return httpClient.get(`${endPoint.LEASECUTOMFIELDS}/${id}`);
};

export const updateLeaseCustomFields = (id, payload) => {
  return httpClient.put(`${endPoint.LEASECUTOMFIELDS}/${id}`, payload);
};
export const createAddendaCustomFields = (payload) => {
  return httpClient.post(endPoint.ADDENDACUTOMFIELDS, payload);
};
export const getAdendaCustomFields = (id) => {
  return httpClient.get(`${endPoint.ADDENDACUTOMFIELDS}/${id}`);
};

export const updateAddendaCustomFields = (id, payload) => {
  return httpClient.put(`${endPoint.ADDENDACUTOMFIELDS}/${id}`, payload);
};
export const createApplicantCustomFields = (payload) => {
  return httpClient.post(endPoint.APPLICANTDISCLOSUREFIELDS, payload);
};
// export const getApplicantCustomFields = (id) => {
//   return httpClient.get(`${endPoint.APPLICANTDISCLOSUREFIELDS}/${id}`);
// };
export const getApplicantCustomFields = (id, template_id = null) => {
  let url = `${endPoint.APPLICANTDISCLOSUREFIELDS}/${id}`;

  if (template_id) {
    url += `?template_id=${template_id}`;
  }

  return httpClient.get(url);
};

export const updateApplicantCustomFields = (id, payload) => {
  return httpClient.put(`${endPoint.APPLICANTDISCLOSUREFIELDS}/${id}`, payload);
};

//lease Form

export const createLeaseForm = (payload) => {
  return httpClient.post(endPoint.LEASEFORM, payload);
};

export const getLeaseForm = () => {
  return httpClient.get(`${endPoint.LEASEFORM}`);
};
export const getLeaseFormByID = (id) => {
  return httpClient.get(`${endPoint.LEASEFORM}/${id}`);
};
export const getLeaseTemplates = (id) => {
  return httpClient.get(`${endPoint.LEASETEMPLATES}/${id}`);
};
export const deleteLeaseTemplate = (id) => {
  return httpClient.delete(`${endPoint.LEASEFORM}/${id}`);
};
export const copyLeaseFormData = (id) => {
  return httpClient.post(`${endPoint.LEASEFORM}/${id}`);
};
export const updateLeaseForm = (id, payload) => {
  return httpClient.put(`${endPoint.LEASEFORM}/${id}`, payload);
};

//Lease Addenda
export const createLeaseAddenda = (payload) => {
  return httpClient.post(endPoint.LEASEADDENDA, payload);
};

export const getLeaseAddendaByID = (id) => {
  return httpClient.get(`${endPoint.LEASEADDENDA}/${id}`);
};

export const updateLeaseAddenda = (id, payload) => {
  return httpClient.put(`${endPoint.LEASEADDENDA}/${id}`, payload);
};
export const deleteLeaseAddenda = (id) => {
  return httpClient.delete(`${endPoint.LEASEADDENDA}/${id}`);
};

//LEASE BUILDER
export const createLeaseTextfield = (payload) => {
  return httpClient.post(endPoint.LEASEBUILDER, payload);
};
export const updateLeaseTextField = (id, payload) => {
  return httpClient.put(`${endPoint.LEASEBUILDER}/${id}`, payload);
};
export const getLeasePdfData = (id) => {
  return httpClient.get(`${endPoint.LEASEBUILDER}/${id}`);
};
export const forgetPassword = (payload) => {
  return httpClient.post(`${endPoint.FORGET_PASSWORD}`, payload);
};
export const passwordReset = (token, payload) => {
  return httpClient.post(`${endPoint.PASSWORD_RESET}/${token}`, payload);
};
//billing section

export const createSelectedPlans = (payload) => {
  return httpClient.post(endPoint.SELECTEDPLAN, payload);
};
export const getSelectedPlans = (id) => {
  return httpClient.get(`${endPoint.SELECTEDPLAN}/${id}`);
};

// jOTfORM sIGNATURE
export const getSignature = (id) => {
  return httpClient.get(`${endPoint.SIGNATURE}/${id}`);
};
// Id Scan
export const createTransaction = (payload) => {
  return httpClient.post(endPoint.IDSCAN, payload);
};
export const getIdReport = (id) => {
  return httpClient.get(`${endPoint.GETIDREPORT}/${id}/${id}`);
};

//
export const createVeriffSession = (payload) => {
  return httpClient.post(endPoint.VERIFF, payload);
};
export const getVeriffDocuments = (id) => {
  return httpClient.get(`${endPoint.VERIFFDOCUMENT}/${id}`, {
    responseType: "blob",
  });
};
