module.exports = {
  PETS: "pet",
  MEMBERS: "member",
  HOUSEHOLDS_DETAILS: "housedholds",
  UPDATE_HOUSEHOLDS_ADDRESS: "updateHouseHoldAddress",
  UPDATE_HOUSEHOLDS_UNITS: "updateHouseHoldUnits",
  HOUSEHOLD: "household",
  VEHICLES: "vehicle",
  ORGINAZATIONS: "organizations",
  ORGINAZATION: "organization/update",
  LEASE: "lease",
  MANAGER: "property_manager",
  INVITE_LEASE: "send_invite_lease",
  SEND_LEASE_NOTIFICATION: "send_sign_lease_notification",
  LEASEfIELDS: "lease/fields_data",
  PROPERTIES: "properties",
  PROPERTY_BY_UID: "propertybyuid",
  LIBRARIES: "libraries",
  LIBRARY: "libraries/list",
  FLOOR: "floor",
  FORM_SETTING: "property_form",
  FEE_CONFIG: "new_fee_config",
  BACKGROUND_CHECK: "background_check",
  LEASE_SETTING: "lease_setting",
  FORM_PRESET: "form_preset",
  FORM_PRESET_LABELS: "form_preset_labels",
  MERCHANT: "merchant",
  USERLOCKSTATUS: "user/lock_status",
  USER: "user",
  APPLICANT_INFO: "applicant_info",
  ADMIN_APPLICANT_INFO: "/admin/applicant_info",
  APPLICANT_INFO_MERGE: "applicant/merge",
  APPLICANT_INFO_ORG: "applicant_info/org",
  PROPERTY_INFO: "applicant_prop_info",
  ADMIN_PROPERTY_INFO: "/admin/applicant_prop_info",
  CURRENT_ADDRESS: "applicant_current_address",
  ADMIN_CURRENT_ADDRESS: "/admin/applicant_current_address",
  PREVIOUS_ADDRESS: "applicant_prev_address",
  ADMIN_PREVIOUS_ADDRESS: "/admin/applicant_prev_address",
  OTHER_SOURCE_INCOME: "applicant_source_income",
  ADMIN_OTHER_SOURCE_INCOME: "/admin/applicant_source_income",
  APPLICATION_PETS: "applicant_pets",
  ADMIN_APPLICATION_PETS: "/admin/applicant_pets",
  APPLICATION_VECHILES: "applicant_vehicles",
  ADMIN_APPLICATION_VECHILES: "/admin/applicant_vehicles",
  APPLICATION_BANKING: "applicant_bank_account",
  APPLICATION_PERSONAL_REFERENCE: "applicant_Personal_ref",
  ADMIN_APPLICATION_PERSONAL_REFERENCE: "/admin/applicant_Personal_ref",
  APPLICATION_EMERGENCYCONTACT: "applicant_Emergency_cts",
  ADMIN_APPLICATION_EMERGENCYCONTACT: "/admin/applicant_Emergency_cts",
  APPLICATION_OTHERQUESTION: "applicant_other_ques",
  ADMIN_APPLICATION_OTHERQUESTION: "/admin/applicant_other_ques",
  APPLICANT_UTILITY: "applicant_utility",
  APPLICANT_UTILITY_AUTH: "applicant_utility_auth",
  CONNECT: "connect",
  APPLICANT_CURR_EMPL: "applicant_current_employer",
  ADMIN_APPLICANT_CURR_EMPL: "/admin/applicant_current_employer",
  APPLICANT_PREV_EMPL: "applicant_previous_employer",
  ADMIN_APPLICANT_PREV_EMPL: "/admin/applicant_previous_employer",
  CREATE_PAYMENT_INTENT: "create-payment-intent",
  CREATE_PAYMENT_DETAILS: "payment-details",
  SEND_INVITE: "invite",
  PROPERTY_SETTING_JOINED: "property_form_joined",
  ARCHIVED: "archived",
  UNARCHIVED: "unarchived",
  BILLING: "billing",
  CREATE_PASSWORD: "create_password",
  PDFBUILDER: "pdftemplate",
  LEASEFORM: "create-lease-form",
  LEASETEMPLATES: "get-lease-form-templates",
  LEASEADDENDA: "create-lease-addenda",
  LEASEBUILDER: "leasetemplate",
  FORGET_PASSWORD: "auth/forget-password",
  PASSWORD_RESET: "auth/password-reset",
  UPDATE_PASSWORD: "user/updatePassword",
  LIBRARY_CUSTOM_FIELDS: "library_custom_fields",
  RETRIEVE_CUSTOMER: "retrieve_customer",
  ADD_BANK_ACCOUNT: "add_bank_account",
  SHOW_DEPOSITS: "show_deposits",
  SELECTEDPLAN: "create-select-Plan",
  ORDERBACKGROUND: "/order-background",
  ADVERSCEACTION: "/order-background/adverseAction",
  GETREPORT: "/order-background/report",
  PREDISCLOSURE: "/pre_disclosure",
  SIGNATURE: "/sign",
  UPLOAD_DOC: "/applicant_uploads",
  IDSCAN: "/create-transaction",
  GETIDREPORT: "/get-transaction",
  INSCRIBESTATUS: "/inscribe_status",
  APPLICANTVERIFY: "/applicant_info/verify",
  LEASECUTOMFIELDS: "/lease_custom_fields",
  ADDENDACUTOMFIELDS: "/addenda_custom_fields",
  APPLICANTDISCLOSUREFIELDS: "/applicant_disclosure_fields",
  VERIFF: "/veriff/sessionId",
  VERIFFDOCUMENT: "/get-veriff-documents",
};
